import React from 'react'
import styled, { css } from 'styled-components'

import Container from 'components/shared/container'
import { Text } from 'components/shared/typography'

export type ColorSingle = {
  color: string
  name: string
  label: string
  type?: string
  textDark?: boolean
  border?: boolean
}

type Palette = {
  description: string
  colors: ColorSingle[]
}

type Props = {
  palettes: Palette[]
}

const Section = styled.section`
  width: 100%;
  padding: 20px 0 0;
  background-color: ${({ theme }) => theme.colors.gray100};

  ${({ theme }) => theme.media.lg.min} {
    background-color: ${({ theme }) => theme.colors.white};
    padding: 30px 0 0;
  }
`

const PalettesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.lg.min} {
    flex-direction: row;
  }
`

const PaletteSingleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  ${({ theme }) => theme.media.lg.min} {
    width: 50%;

    :first-of-type {
      padding-right: 5.5%;
    }

    & > ${Text} {
      height: 100px;
      max-width: 340px;
    }

    &:before {
      content: '';
      display: block;
      width: 50px;
      height: 8px;
      margin-bottom: 20px;
      background-color: ${({ theme }) => theme.colors.primary};
      border-radius: 5px;
    }
  }
`

const ColorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 15px;

  ${({ theme }) => theme.media.xl.min} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
`

const ColorSingleWrapper = styled.div<{ color: string; withBorder?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
  padding: 8px;
  background-color: ${({ color }) => color};

  ${({ withBorder }) =>
    withBorder &&
    css`
      padding: 7px;
      border: 1px solid ${({ theme }) => theme.colors.black};
    `}

  ${Text}:last-child {
    position: absolute;
    top: 10px;
    right: 20px;
  }

  ${({ theme }) => theme.media.xl.min} {
    margin: 0;
  }
`

const Colors: React.FC<Props> = ({ palettes }) => {
  return (
    <Section>
      <Container>
        <PalettesWrapper>
          {palettes.map(({ description, colors }, index) => (
            <PaletteSingleWrapper key={index}>
              <Text
                size={14}
                weight={400}
                themecolor="black"
                dangerouslySetInnerHTML={{ __html: description }}
              />
              <ColorsWrapper>
                {colors.map(
                  ({ color, name, label, type, textDark, border }) => (
                    <ColorSingleWrapper
                      key={color}
                      color={color}
                      withBorder={border}
                    >
                      <Text
                        size={14}
                        weight={400}
                        themecolor={textDark ? 'black' : 'white'}
                        transform="uppercase"
                        margin="0"
                        dangerouslySetInnerHTML={{ __html: name }}
                      />
                      <Text
                        size={14}
                        weight={400}
                        themecolor={textDark ? 'black' : 'white'}
                        dangerouslySetInnerHTML={{ __html: label }}
                      />
                      {type && (
                        <Text
                          size={14}
                          weight={400}
                          themecolor={textDark ? 'black' : 'white'}
                          transform="uppercase"
                          dangerouslySetInnerHTML={{ __html: type }}
                        />
                      )}
                    </ColorSingleWrapper>
                  )
                )}
              </ColorsWrapper>
            </PaletteSingleWrapper>
          ))}
        </PalettesWrapper>
      </Container>
    </Section>
  )
}

export default Colors
