import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import SwiperCore, { Grid } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/grid'

import Container from 'components/shared/container'
import { Heading, Text } from 'components/shared/typography'
import LazyImage from 'components/shared/lazyImage'

import { ImageDataLike } from 'gatsby-plugin-image'

import useBreakpoint from 'hooks/useBreakpoint'

import arrowIcon from 'assets/icons/arrow-primary.svg'

type Product = {
  img: ImageDataLike
  alt: string
  label: string
  link: string
}

type Props = {
  heading: string
  products: Product[]
}

const Section = styled.section`
  position: relative;
  width: 100%;
  background-color: #e6eaee;
  padding: 40px 0 60px 0;
`

const StyledContainer = styled(Container)`
  position: relative;
`

const ControlButton = styled.button`
  position: absolute;
  width: 35px;
  height: 35px;
  top: 0;
  left: 20px;
  transform: rotate(180deg);

  &:nth-of-type(2) {
    left: auto;
    right: 20px;
    transform: rotate(0);
  }

  ${({ theme }) => theme.media.lg.min} {
    width: 40px;
    height: 40px;
    top: 50%;
    left: 0;
    transform: rotate(180deg);

    &:nth-of-type(2) {
      left: auto;
      right: 0;
      transform: rotate(0);
    }
  }
`

const SwiperWrapper = styled.div`
  max-width: 400px;
  margin: 0 auto;
  cursor: grab;

  ${({ theme }) => theme.media.sm.min} {
    max-width: 600px;
  }

  ${({ theme }) => theme.media.lg.min} {
    max-width: unset;
  }
`

const SlideInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px 20px;
  ${({ theme }) => theme.media.xl.min} {
    margin: 0 20px 0;
  }
`

const ProductImageWrapper = styled.div`
  height: 100px;
  transition: 0.25s ease;

  //Gatsby image wrapper
  & > div {
    height: 100%;
  }

  ${({ theme }) => theme.media.sm.min} {
    height: 150px;
  }

  ${({ theme }) => theme.media.lg.min} {
    height: 120px;
  }

  ${({ theme }) => theme.media.xl.min} {
    height: 150px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    height: 170px;
  }
`

const Hr = styled.div`
  width: 100%;
  max-width: 160px;
  height: 10px;
  margin: 20px 0 5px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
  transition: 0.25s ease;

  ${({ theme }) => theme.media.sm.min} {
    max-width: 230px;
  }

  ${({ theme }) => theme.media.lg.min} {
    max-width: 190px;
  }

  ${({ theme }) => theme.media.xl.min} {
    max-width: 230px;
  }

  ${({ theme }) => theme.media.xl.min} {
    max-width: 280px;
  }
`

const Products: React.FC<Props> = ({ heading, products }) => {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null)

  const { lg } = useBreakpoint()

  const calculateOrder = (itemNumber: number) => {
    if (itemNumber === 3) return 5
    if (itemNumber === 4) return 6

    return 'unset'
  }

  const goPrev = () => {
    swiper?.slidePrev()
  }

  const goNext = () => {
    swiper?.slideNext()
  }

  return (
    <Section
      title={heading ? heading.replace(/<br\/> /g, '') : ''}
      id="products"
    >
      <StyledContainer>
        <Heading
          as="h2"
          size={28}
          weight={600}
          align="center"
          themecolor="primary"
          margin="50px"
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        <SwiperWrapper>
          <Swiper
            modules={[Grid]}
            grid={{
              rows: lg ? 1 : 2,
              fill: 'row',
            }}
            slidesPerView={lg ? 4 : 2}
            onSwiper={(initSwiper) => setSwiper(initSwiper)}
            speed={1000}
          >
            {products?.map(({ img, alt, label, link }, index) => {
              return (
                <SwiperSlide
                  key={label + index}
                  style={{
                    order: lg ? 'unset' : calculateOrder(index + 1),
                  }}
                >
                  <SlideInnerWrapper as={Link} to={`${link}/`}>
                    <ProductImageWrapper>
                      <LazyImage src={img} alt={alt} objectFit="contain" />
                    </ProductImageWrapper>
                    <Hr />
                    <Text
                      size={15}
                      weight={700}
                      themecolor="black"
                      transform="uppercase"
                      align="center"
                      dangerouslySetInnerHTML={{ __html: label }}
                    />
                  </SlideInnerWrapper>
                </SwiperSlide>
              )
            })}
          </Swiper>
          {lg && (
            <>
              <ControlButton onClick={goPrev}>
                <img src={arrowIcon} alt="" />
              </ControlButton>
              <ControlButton onClick={goNext}>
                <img src={arrowIcon} alt="" />
              </ControlButton>
            </>
          )}
        </SwiperWrapper>
        {!lg && (
          <>
            <ControlButton onClick={goPrev}>
              <img src={arrowIcon} alt="" />
            </ControlButton>
            <ControlButton onClick={goNext}>
              <img src={arrowIcon} alt="" />
            </ControlButton>
          </>
        )}
      </StyledContainer>
    </Section>
  )
}

export default Products
