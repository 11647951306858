import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import Icon from 'components/shared/icon'
import { Text } from 'components/shared/typography'

import pdfIcon from 'assets/icons/pdf.png'
import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  pdfLink: string
}

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;

  ${({ theme }) => theme.media.lg.min} {
    flex-direction: row-reverse;
    justify-content: flex-end;
    transform: translateX(-8px);

    ${Text} {
      margin-left: 20px;
    }
  }
`

const FencePdf: React.FC<Props> = ({ pdfLink }) => {
  const { lg } = useBreakpoint()

  return (
    <StyledContainer>
      <Text
        as="a"
        href={pdfLink}
        target="_blank"
        size={lg ? 20 : 18}
        weight={500}
        themecolor="black"
        margin={lg ? '0' : '20px'}
        dangerouslySetInnerHTML={{
          __html: `Karta techniczna produktu${lg ? '' : ':'}`,
        }}
      />
      <a href={pdfLink} target="_blank" rel="noopener noreferrer nofollow">
        <Icon src={pdfIcon} size={80} alt="pdf" />
      </a>
    </StyledContainer>
  )
}

export default FencePdf
