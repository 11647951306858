import React from 'react'
import styled, { css } from 'styled-components'

import Container from 'components/shared/container'
import { Heading } from 'components/shared/typography'

import exampleVideo from 'assets/images/example-video.mp4'

import LazyLoad from 'react-lazyload'

type Props = {
  heading?: string
  marginBottom?: boolean
  videoUrl?: string
  videoUrl2?: string
}

const Section = styled.section<{ marginBottom?: boolean }>`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  padding-top: 40px;

  ${({ theme }) => theme.media.s.min} {
    ${Heading} {
      br {
        display: none;
      }
    }
  }

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: 60px;
    `}
`

const IframeWrapper = styled.div`
  width: 100%;
  height: 540px;
  overflow: hidden;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;

  ${({ theme }) => theme.media.lg.min} {
    height: 620px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    height: 680px;
    border-top-left-radius: 80px;
    border-top-right-radius: 80px;
  }
`

const VideoWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  background-color: ${({ theme }) => theme.colors.black};
  video {
    max-width: 900px;
  }
  ${({ theme }) => theme.media.lg.min} {
    video {
      max-width: 1200px;
    }
  }
  ${({ theme }) => theme.media.xxl.min} {
    border-top-left-radius: 80px;
    border-top-right-radius: 80px;
    video {
      max-width: 1400px;
    }
  }
`

const Video: React.FC<Props> = ({
  heading,
  marginBottom,
  videoUrl,
  videoUrl2,
}) => {
  return (
    <Section
      id="video-section"
      title={heading ? heading.replace(/<br\/> /g, '') : ''}
      marginBottom={marginBottom}
    >
      <Container>
        {heading && (
          <Heading
            as="h2"
            size={28}
            weight={600}
            align="center"
            themecolor="primary"
            margin="50px"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
        )}
      </Container>
      <LazyLoad
        offset={500}
        height="100%"
        style={{
          width: '100%',
        }}
        once
      >
        {videoUrl ? (
          <IframeWrapper>
            <iframe
              width="100%"
              height="100%"
              src={videoUrl}
              title="Gatigo youtube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </IframeWrapper>
        ) : (
          <VideoWrapper>
            <video id="video" loop autoPlay playsInline muted>
              <source src={videoUrl2 || exampleVideo} />
            </video>
          </VideoWrapper>
        )}
      </LazyLoad>
    </Section>
  )
}

export default Video
