import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import { Text } from 'components/shared/typography'
import Button from 'components/shared/button'

import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  info1: string
  info2: string
}

const Section = styled.section`
  width: 100%;
  padding: 0 0 20px 0;
  background-color: ${({ theme }) => theme.colors.gray100};

  ${({ theme }) => theme.media.lg.min} {
    padding: 20px 0 40px 0;
    background-color: ${({ theme }) => theme.colors.white};
  }

  ${({ theme }) => theme.media.xxl.min} {
    padding: 40px 0 80px 0;
  }
`

const InnerWrapper = styled.div`
  ${({ theme }) => theme.media.lg.min} {
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: center;
    padding-left: 30px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 10px;
      background-color: ${({ theme }) => theme.colors.primary};
      border-radius: 5px;
    }
  }
`

const InfoWrapper = styled.div`
  ${({ theme }) => theme.media.lg.min} {
    :nth-of-type(2) {
      position: relative;
      margin-left: 20px;
      margin-right: 20px;
      padding-left: 30px;
      padding-right: 30px;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 10px;
        background-color: ${({ theme }) => theme.colors.primary};
        border-radius: 5px;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 10px;
        background-color: ${({ theme }) => theme.colors.primary};
        border-radius: 5px;
      }
    }
  }
`

const StyledButton = styled(Button)`
  display: block;
  align-self: center;
  padding: 15px;
  font-size: 15px;

  ${({ theme }) => theme.media.xxl.min} {
    font-size: 17px;
    margin-left: 30px;
  }
`

const Info: React.FC<Props> = ({ info1, info2 }) => {
  const { lg, xxl } = useBreakpoint()

  return (
    <Section>
      <Container>
        <InnerWrapper>
          <InfoWrapper>
            <Text
              size={xxl ? 16 : 14}
              weight={400}
              themecolor="black"
              dangerouslySetInnerHTML={{ __html: info1 }}
            />
          </InfoWrapper>
          {lg && info2 && (
            <InfoWrapper>
              <Text
                size={xxl ? 16 : 14}
                weight={400}
                themecolor="black"
                dangerouslySetInnerHTML={{ __html: info2 }}
              />
            </InfoWrapper>
          )}
          {lg && (
            <StyledButton as="a" href="/gdzie-kupic/" primary300 uppercase>
              zakup i realizacja
            </StyledButton>
          )}
        </InnerWrapper>
      </Container>
    </Section>
  )
}

export default Info
