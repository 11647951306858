import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import LazyImage from 'components/shared/lazyImage'

import { ImageDataLike } from 'gatsby-plugin-image'

type GalleryItem = {
  img: ImageDataLike
  alt: string
}

type Props = {
  gallery: GalleryItem[]
}

const Section = styled.section`
  background-color: ${({ theme }) => theme.colors.gray100};
`

const StyledContainer = styled(Container)`
  ${({ theme }) => theme.media.lg.min} {
    max-width: unset;
    padding: 0;
    margin: 0;
  }
`

const TilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.media.lg.min} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
`

const TileSingleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 40px;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.colors.gray300};
  border-radius: 15px;

  transition: 0.25s;

  ${({ theme }) => theme.media.lg.min} {
    margin: 0;
    border-radius: 0;
    padding: 25px;

    div {
      max-height: 100%;
    }

    :nth-child(1) {
      grid-area: 1 / 1 / 2 / 2;
      height: 200px;
      background-color: ${({ theme }) => theme.colors.gray};
    }

    :nth-child(2) {
      grid-area: 2 / 1 / 3 / 2;
      height: 200px;
    }

    :nth-child(3) {
      grid-area: 1 / 2 / 3 / 3;
      height: 400px;
      padding: 60px;
      background-color: ${({ theme }) => theme.colors.gray100};
    }
  }

  ${({ theme }) => theme.media.xxl.min} {
    :nth-child(1) {
      height: 240px;
    }

    :nth-child(2) {
      height: 240px;
    }

    :nth-child(3) {
      height: 480px;
    }
  }
`

const Tiles: React.FC<Props> = ({ gallery }) => {
  return (
    <Section>
      <StyledContainer>
        <TilesWrapper>
          {gallery.map(({ img, alt }, index) => (
            <TileSingleWrapper key={alt + index}>
              <LazyImage src={img} alt={alt} objectFit="contain" />
            </TileSingleWrapper>
          ))}
        </TilesWrapper>
      </StyledContainer>
    </Section>
  )
}

export default Tiles
