import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Hero from 'components/layout/Sections/Hero'
import About from 'components/layout/Sections/About/Gate'
import Visualization from 'components/layout/Sections/GateVisualization'
import Colors from 'components/layout/Sections/Colors/Colors'
import Info from 'components/layout/Sections/About/Gate/Secondary'
import Products from 'components/layout/Sections/Products'
import VideoSection from 'components/layout/Sections/Video'

import Navigation from 'components/layout/Navigation/index'

import ProductPdf from 'components/layout/ProductPdf'

const innovations = [
  'brama-teleskopowa-3-segmentowa',
  'brama-narozna',
  'brama-przeciwhalasowa',
  'brama-z-fundamentem',
]

const noIndexGates = [
  'brama-teleskopowa-3-segmentowa',
  'brama-skladana-2-2',
  'brama-skladana-2-1',
]

const colors1 = [
  {
    color: '#4f2e21',
    name: 'RAL 8017',
    label: 'Mocca brąz',
    type: 'MDS',
  },
  {
    color: '#413f3d',
    name: 'RAL 7016',
    label: 'Księżycowy antracyt',
    type: 'MDS',
  },
  {
    color: '#534f56',
    name: 'RAL 7024',
    label: 'Wulkaniczny grafit',
    type: 'MDS',
  },
  {
    color: '#15130e',
    name: 'RAL 9005',
    label: 'Karbowana czerń',
    type: 'MDS',
  },
  {
    color: '#ffee36',
    name: 'RAL 1018',
    label: 'Słoneczny żółty',
    type: 'MDS',
    textDark: true,
  },
  {
    color: '#ea7801',
    name: 'RAL 2003',
    label: 'Szafranowy pomarańcz',
    type: 'MDS',
  },
  {
    color: '#8e1a19',
    name: 'RAL 3004',
    label: 'Winny czerwony',
    type: 'MDS',
  },
  {
    color: '#10263c',
    name: 'RAL 5003',
    label: 'Klasyczny szafir',
    type: 'MDS',
  },
]

const colors2 = [
  {
    color: '#00466d',
    name: 'RAL 5010',
    label: 'Intensywny kobalt',
    type: 'MDS',
  },
  {
    color: '#4fa331',
    name: 'RAL 6017',
    label: 'Energetyczna limonka',
    type: 'MDS',
  },
  {
    color: '#1e2427',
    name: 'RAL 7021',
    label: 'Mroźne marengo',
    type: 'MDS',
  },
  {
    color: '#707a7e',
    name: 'RAL 7030',
    label: 'Stalowy szary',
    type: 'MDS',
  },
  {
    color: '#084c35',
    name: 'RAL 6005',
    label: 'Soczysta zieleń',
    type: 'MDS',
  },
  {
    color: '#473c2d',
    name: 'RAL 8019',
    label: 'Czekoladowy brąz',
    type: 'MDS',
  },
  {
    color: '#b3b0af',
    name: 'RAL 9006',
    label: 'Metalizowane srebro',
    type: 'MDS',
  },
  {
    color: '#f0f2f4',
    name: 'RAL 9016',
    label: 'Porcelanowa biel',
    type: 'MDS',
    textDark: true,
    border: true,
  },
]

const GateModelPage: React.FC<PageProps> = ({ data, pageContext }) => {
  const PAGE = data?.wpGatesArticle?.bramaSingle
  const PAGE_SEO = data?.wpGatesArticle?.seo
  const HOMEPAGE = data?.wpPage?.bramyWjazdowe
  const VIDEO_URL = PAGE?.gateSingleVideoFile?.localFile?.publicURL

  const belongsToInnovations = innovations.includes(data?.wpGatesArticle?.slug)

  const GALLERY = React.useMemo(
    () => [
      {
        img: PAGE?.gateSingleImgLeft1?.localFile?.childImageSharp
          ?.gatsbyImageData,
        alt: 'drawing-1',
      },
      {
        img: PAGE?.gateSingleImgLeft2?.localFile?.childImageSharp
          ?.gatsbyImageData,
        alt: 'drawing-2',
      },
      {
        img: PAGE?.gateSingleImgRight?.localFile?.childImageSharp
          ?.gatsbyImageData,
        alt: 'drawing-3',
      },
    ],
    []
  )

  const PALETTES = React.useMemo(
    () => [
      { description: PAGE?.gateSingleColorsDescriptionLeft, colors: colors1 },
      { description: PAGE?.gateSingleColorsDescriptionRight, colors: colors2 },
    ],
    []
  )

  const PRODUCTS = React.useMemo(
    () =>
      HOMEPAGE?.gateTypeGate?.map((item) => ({
        img: item?.gateTypeGateImg.localFile.childImageSharp.gatsbyImageData,
        alt: 'gate-type',
        label: item?.gateTypeGateNameProduct,
        link: item?.gateTypeGateLink,
      })),
    []
  )

  const breadcrumbs = {
    mobile: [
      {
        label: 'Bramy',
        link: '/bramy-wjazdowe/',
      },
      {
        label: PAGE?.gateSingleTitle,
      },
    ],
    desktop: [
      {
        label: 'Home',
        link: '/',
      },
      {
        label: 'Bramy',
        link: '/bramy-wjazdowe/',
      },
      {
        label: PAGE?.gateSingleTitle,
      },
    ],
  }

  const noIndex = noIndexGates.includes(pageContext.slug)

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
        noIndex={noIndex}
      />
      <Navigation />
      <Hero
        imgMobile={
          PAGE?.gateSingleImg?.localFile?.childImageSharp?.gatsbyImageData
        }
        imgDesktop={
          PAGE?.gateSingleImg?.localFile?.childImageSharp?.gatsbyImageData
        }
        headingMobile={PAGE?.gateSingleTitle}
        headingDesktop={PAGE?.gateSingleTitle}
        breadcrumbs={breadcrumbs}
      />
      <About
        description1={PAGE?.gateSingleDescriptionLeft}
        description2={PAGE?.gateSingleDescriptionRight}
      />
      <Visualization gallery={GALLERY} />
      {PAGE?.gatePdfLink && <ProductPdf pdfLink={PAGE?.gatePdfLink} />}
      {!belongsToInnovations && <Colors palettes={PALETTES} />}
      <Info
        info1={PAGE?.gateSinglePurschaseRealisationDescription1}
        info2={PAGE?.gateSinglePurschaseRealisationDescription2}
      />
      {VIDEO_URL && <VideoSection videoUrl2={VIDEO_URL} marginBottom />}
      <Products heading="Nasze produkty" products={PRODUCTS} />
    </Layout>
  )
}

export default GateModelPage

export const query = graphql`
  query GateModelPage($slug: String) {
    wpPage(slug: { regex: "/bramy/" }) {
      bramyWjazdowe {
        gateTypeGate {
          gateTypeGateDescription
          gateTypeGateNameProduct
          gateTypeGateLink
          gateTypeGateImg {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 310
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
    wpGatesArticle(slug: { eq: $slug }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
      }
      id
      slug
      bramaSingle {
        gateSingleTitle
        gateSingleDescriptionLeft
        gateSingleDescriptionRight
        gateSingleColorsDescriptionLeft
        gateSingleColorsDescriptionRight
        gateSinglePurschaseRealisationDescription1
        gateSinglePurschaseRealisationDescription2
        gatePdfLink
        gateSingleImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        gateSingleImgLeft1 {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
        gateSingleImgLeft2 {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
        gateSingleImgRight {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
        gateSingleVideoFile {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`
